import Moment from "moment";

export default {
  name: "OfficeStatus",
  data() {
    return {
      property: {
        listElement: {
          officeStatus: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
            isLoadinng: false,
          },
        },
      },
      table: {
        data: {
          officeStatus: [],
        },
      },
    };
  },
  methods: {
    Moment: Moment,
    async closeOperationalProcess() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Tutup Semua Cabang ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.listElement.officeStatus.isLoadinng = true;
            try {
              const resp = await this.$store.dispatch({
                reqUrl: "operational-process/all",
                endPoint: "process",
                type: "PUT_DATA",
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                  this.helperGetDataTableOfficeStatus();
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.listElement.officeStatus.isLoadinng = false;
              }, 1000);
            }
          },
        });
      }
    },
    async helperGetDataTableOfficeStatus() {
      this.table.data.officeStatus = [];
      this.property.listElement.officeStatus.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          reqUrl: "operational-process/office-status",
          type: "GET_LIST_DATA",
          endPoint: "process",
        });
        console.log(resp);
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.officeStatus = resp.data.data;
            this.property.listElement.officeStatus.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataOffice(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorOffice(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.officeStatus.downloading = false;
        }, timeout);
      }
    },
    handleCatchErrorOffice(error) {
      console.log(error.response);
      this.table.data.officeStatus = [];
      this.property.listElement.officeStatus.rows = 0;
      this.property.listElement.officeStatus.currentPage = 1;
      this.property.listElement.officeStatus.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    failedGetDataOffice(resp) {
      this.table.data.officeStatus = [];
      this.property.listElement.officeStatus.rows = 0;
      this.property.listElement.officeStatus.message = resp.data.message;
    },
  },
  mounted() {
    this.helperGetDataTableOfficeStatus();
  },
};
